<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createCategory">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('Categoría') }}</h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="category-name"
              >
                <b-form-input
                  v-model="name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('Padre')"
              label-for="category-parent"
            >
              <SelectCategories v-model="parent" />
            </b-form-group>
          </b-col>
          <b-col sm="6" v-if="selectTypes.length > 1" >
            <validation-provider
              #default="{ errors }"
              :name="$t('Tipo')"
              rules="required"
            >
              <b-form-group
                :label="$t('Tipo')"
                label-for="category-type"
              >
                <v-select
                  v-model="type"
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  :options="selectTypes"
                  :placeholder="$t('Tipo')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-group
              :label="$t('Descripcion')"
              label-for="location-description"
            >
              <quill-editor
                v-model="description"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right" >
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SelectCategories from "@/components/selectCategories/SelectCategories.vue"
export default {
  components: {
    SelectCategories,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      name: '',
      address: '',
      parent: '',
      type: '',
      description: '',
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
      selectLocations: 'locations/getSelectLocations',
      selectTypes: 'clients/getSelectTypesAvailables',
    }),
  },
  methods: {
    ...mapActions({
      create: 'categories/create',
    }),

    handleSubmit() {
      this.$refs.createCategory.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          console.log(formData);
          /* if (formData) {
            this.create({ category: formData })
          } */
        }
      })
    },
    createFormData() {
      const data = new FormData()
      if (this.parent) {
        data.append('parent_id', this.parent.id)
      } else {
        data.append('parent_id', '')
      }
      if (this.currentClient) {
        data.append('client_id', this.currentClient.id)
      } else {
        data.append('client_id', '')
      }
      data.append('name', this.name)
      data.append('type', this.type.value)
      data.append('ref', this.name)
      data.append('description', this.description)
      data.append('company_id', this.currentCompany.id)

      return data
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.form-control:disabled{
  background-color: #f8f8f8 !important;
}
</style>
